'use client';

import { MindfulAudioImg } from '@/config/img/mindfulAudio';
import { useParams, useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { dealLng } from '@/utils';
import { Image } from '@nextui-org/react';

export default function PlayItem(props: {
  goPath: string;
}) {
  const router = useRouter();
  const params = useParams();

  const handleBookClick = useCallback(() => {
    const lng = params.lng || 'en';
    if (props.goPath) {
      return ;
      router.push('/' + dealLng(lng as string) + props.goPath);
    }
  }, [params, router, props]);
  return (
    <Image
      src={ MindfulAudioImg.PlayAudio }
      alt='play' width={ 45 }
      height={ 45 } 
      className='absolute bottom-4 left-[160px] z-30 cursor-pointer'
      onClick={ handleBookClick }
    />
  );
}
