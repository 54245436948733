
'use client';

import { useClientTranslation } from '@/hook/translate';
import { dealLng } from '@/utils';
import { Image } from '@nextui-org/react';
import clsx from 'clsx';
import { useParams, useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';
const categoryList: ICategoryList[] = [
  {
    name: 'Personal Development',
    id: '1.1',
    children: [
      {
        name: 'Business Strategy',
        id: '1-1',
      },
      {
        name: 'Parent-Child Communication',
        id: '1-2',
      },
      {
        name: 'Baby & Toddler',
        id: '1-3',
      },
      {
        name: 'Business Strategy',
        id: '1-4',
      },
      {
        name: 'Business Strategy',
        id: '1-5',
      },
    ],
  },
  {
    name: 'Psychology & Happiness',
    id: '2.1',
    children: [
      {
        name: 'Business Strategy',
        id: '2-1',
      },
      {
        name: 'Parent-Child Communication',
        id: '2-2',
      },
      {
        name: 'Baby & Toddler',
        id: '2-3',
      },
      {
        name: 'Business Strategy',
        id: '2-4',
      },
    ],
  },
  {
    name: 'Management & Business',
    id: '3.1',
    children: [
      {
        name: 'Business Strategy',
        id: '3-1',
      },
      {
        name: 'Parent-Child Communication',
        id: '3-2',
      },
      {
        name: 'Baby & Toddler',
        id: '3-3',
      },
    ],
  },
  {
    name: 'Fiction Classics',
    id: '4.1',
    children: [
      {
        name: 'Business Strategy',
        id: '4-1',
      },
      {
        name: 'Parent-Child Communication',
        id: '4-2',
      },
      {
        name: 'Baby & Toddler',
        id: '4-3',
      },
    ],
  },
  {
    name: 'Finance & Investments',
    id: '5.1',
    children: [
      {
        name: 'Business Strategy',
        id: '5-1',
      },
      {
        name: 'Parent-Child Communication',
        id: '5-2',
      },
      {
        name: 'Baby & Toddler',
        id: '5-3',
      },
    ],
  },
];

interface ICategoryList {
  name: string;
  id: string;
  children: {
    name: string;
    id: string;
  }[];
}

function getCorrectPath(path: string[], list: ICategoryList[]) {
  const [categoryPath, topicPath] = path;
  const category = list.find((item) => item.name === categoryPath);
  const index = list.findIndex((item) => item.name === categoryPath);
  if (category) {
    let topData: ICategoryList[] = [];
    if (index - 2 < 0) {
      topData = [...list.slice(index - 2), ...list.slice(0, index + 3)];
    } else if (index + 2 > list.length - 1) {
      topData = [...list.slice(index - 2), ...list.slice(0, (index + 3) % list.length)];
    } else {
      topData = list.slice(index - 2, index + 3);
    }
    const topic = category.children.findIndex((item) => item.name === topicPath);
    if (topic > -1 || topicPath === 'all') {
      return {
        isCorrect: true,
        path: [],
        categoryData: {
          topData: topData,
          subData: category.children,
        },
      };
    }
    return {
      isCorrect: false,
      path: [category, 'all'],
      categoryData: {
        topData: topData,
        subData: category.children,
      },
    };
  }
  return {
    isCorrect: false,
    path: [list[0].name, 'all'],
    categoryData: {
      topData: list.slice(0, 5),
      subData: list[0].children,
    },
  };
}


export default function HomeCategory() {
  // const allCategories = ['Personal Development', 'Psychology & Happiness', 'Management & Business', 'Fiction Classics', 'Finance & Investments',
  //   'Biography & Memoir', 'Society & Culture', 'Parenting & Education', 'Art & Creativity', 'Philosophy & Religion', 'Health & Sports',
  //   'Nature & Science', 'History & Politics', 'Philosophy & Religion1',
  // ];
  // const subTabData = ['All', 'Business Strategy', 'Parent-Child Communication', 'Baby & Toddler', 'Business Strategy', 'Schoolsda'];

  const { t } = useClientTranslation();
  const [subData, setSubData] = useState<{ name: string; id: string; }[]>([]);
  const [topData, setTopData] = useState<ICategoryList[]>([]);

  const router = useRouter();
  const params = useParams();
  const [category, topic, pageNum] = [...params.category].map(item => decodeURIComponent(item));


  const handleCategoryClick = useCallback((categoryPath: string) => {
    const lng = params.lng;

    console.log(new Date().toLocaleString(), 'components/HomeCategory.tsx/[/${dealLng(lng as string)}/home/${categoryPath}/all/1#cate]:', `${dealLng(lng as string)}/home/${categoryPath}/all/1#cate`);

    router.push(`${dealLng(lng as string)}/home/${categoryPath}/all/1#cate`);
  }, [params, router]);

  const handleTopicCLick = useCallback((topicPath: string) => {
    const lng = params.lng;
    router.push(`${dealLng(lng as string)}/home/${category}/${topicPath}/1#cate`);
  }, [params, router, category]);

  useEffect(() => {
    const {
      isCorrect,
      path,
      categoryData,
    } = getCorrectPath([ category, topic, pageNum ], categoryList);
    if (!isCorrect) {
      router.replace(`${dealLng(params.lng as string)}/home/${path[0]}/${path[1]}/1`);
    }
    setSubData(categoryData.subData);
    setTopData(categoryData.topData);
  }, [params, router, category, topic, pageNum]);




  return (
    <section className='category' id='cate'>
      <div className='pc-category-wrap px-[150px] pt-[50px] flex max-md:hidden'>
        {
          topData.map((item, index) => {
            return (
              <div
                className={
                  clsx('relative h-[128px] px-5 flex items-center  overflow-hidden mr-6 last:mr-0  cursor-pointer font-bold',
                    item.name === category 
                      ? 'bg-[#CDF564] text-black' 
                      : 'bg-cover bg-no-repeat bg-[url("/home-category-3.png")] bg-black/60 text-white ',
                  )
                } 
                key={item.id}
                onClick={() => handleCategoryClick(item.name)}
              >
                <span className='w-[256px] first:w-[208px] last:w-[256px] text-center text-24-35 break-words'>
                  {item.name}
                </span>
                {
                  index === 0 ?
                    <span className='absolute top-0 left-0 flex items-center justify-center w-[100px] h-[128px] bg-[url("/home-left-cover.png")]'>
                      <Image 
                        src='/home-left.png' 
                        alt='left-category' 
                        className='cursor-pointer' 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategoryClick(topData[1].name);
                        }}
                      />
                    </span>
                    : null
                }
                {
                  index === 4 ?
                    <span className='absolute top-0  right-0 flex items-center justify-center w-[100px] h-[128px] bg-[url("/home-right-cover.png")]'>
                      <Image 
                        src='/home-right.png' 
                        alt='left-category' 
                        className='cursor-pointer'
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCategoryClick(topData[3].name);
                        }}
                        />
                    </span>
                    : null
                }
              </div>
            );
          })
        }
      </div>
      <div className='hidden max-md:flex pt-[25px] flex-nowrap overflow-auto'>
        {
          topData.map((cate) => {
            return (
              <div 
                key={cate.id}
                className={
                clsx('h-20 flex justify-center items-center grow-0 shrink mr-3',
                  cate.name === category
                    ? 'bg-[#CDF564] text-black' 
                    : 'bg-cover bg-no-repeat bg-[url("/home-category-3.png")] text-white ',
                )}
                onClick={() => handleCategoryClick(cate.name)}
              >
                <span className=' w-40 break-words text-center font-semibold'>{cate.name}</span>
              </div>
            );
          })
        }
      </div>
      <section className='sub-list'>
        {/* pc */}
        <div className='flex w-full flex-nowrap  px-[150px] pt-[30px] pb-20 max-md:hidden'>
          <span
            className={
              clsx('mr-[10px] px-[25px] py-3 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer',
                topic === 'all' && 'bg-[#CDF564] border-none',
              )
            }
          onClick={ () => handleTopicCLick('all') }
          >{t('all')}</span>
          {
            subData.map((item) => {
              return (
                <span
                  key={item.id}
                  className={
                    clsx('mr-[10px] px-[25px] py-3 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer',
                      topic === item.name && 'bg-[#CDF564] border-none',
                    )
                  }
                onClick={ () => handleTopicCLick(item.name) }
                >{item.name}</span>
              );
            })
          }
        </div>

        {/* mobile */}
        <div className='w-full flex-nowrap  px-[15px] pt-[15px] pb-6 overflow-auto hidden max-md:flex'>
          <span
            className={
              clsx('mr-2 px-5 py-[10px] text-14-19 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer grow shrink-0',
                topic === 'all' && 'bg-[#CDF564] border-none',
              )
            }

          onClick={ (e: any) => {
            e.target?.scrollIntoView(true);
            handleTopicCLick('all');
          } }
          >{t('all')}</span>
          {
            subData.map((item) => {
              return (
                <span
                  key={item.id}
                  className={
                    clsx('mr-2 px-5 py-[10px] text-14-19 border border-solid border-[#CBCBCB] rounded-3xl cursor-pointer grow shrink-0',
                      topic === item.name && 'bg-[#CDF564] border-none',
                    )
                  }

                onClick={ (e: any) => {
                  e.target?.scrollIntoView(true);
                  handleTopicCLick(item.name);
                } }
                >{item.name}</span>
              );
            })
          }
        </div>
      </section>
    </section>
  );
}
